
import { ComputedRef, computed, defineComponent, ref, nextTick } from 'vue';
import { useStore } from 'vuex';

import AudioPodcast from '@/components/AudioPodcast/AudioPodcast.vue';
import ContentIntro from '@/components/ContentIntro/ContentIntro.vue';
import Hero from '@/components/Hero/Hero.vue';
import Showcase from '@/components/Showcase/Showcase.vue';
import { scrollIntoView } from '@/use/useScroll';
import Button from '@/components/Button/Button.vue';
import { CLUB_LOYALTY_URL } from '@/modules/auth/constants';

import { Podcast, PointTypesEnum } from '@/types';
const heroImages = {
  background: require('@/assets/podcasts/hero.jpg'),
  logo: require('@/assets/shop/product-listing/logo-my-iqos-64-dark@3x.png'),
};

const API_COOLDOWN_BETWEEN_CALLS = 5000; // API does not allow more than 1 request every 4 seconds

export default defineComponent({
  components: {
    AudioPodcast,
    ContentIntro,
    Hero,
    Showcase,
    Button,
  },
  setup() {
    const store = useStore();

    const userId = store.getters['authModule/userId'];
    const {
      headers: { 'x-login-token': token },
    } = store.getters['authModule/authHeaders'];

    const podcasts: ComputedRef<Podcast[]> = computed(
      () => store.state.podcastsModule.podcasts
    );

    const currentPlayingTrack = ref(null);
    const isSurveyDisplayed = ref(false);
    const refSurvey = ref(null);
    const surveyUrl = ref('');

    const onListenToPodcast = (index: number) => {
      // Force reset and unmounting of audio player
      currentPlayingTrack.value = null;
      // Fetch current progress status from BE
      store.dispatch(
        'podcastsModule/getAudioFragmentsFromPodcast',
        podcasts.value[index].trackingAction
      );
      // Wait to post the first fragment
      setTimeout(() => {
        onAudioFragmentListened({
          progress: 0,
          trackAction: podcasts.value[index].trackingAction,
        });
      }, API_COOLDOWN_BETWEEN_CALLS);
      nextTick(() => {
        currentPlayingTrack.value = podcasts.value[index];
      });
    };

    const onPodcastFinished = (podcast: Podcast) =>
      store.dispatch(
        'podcastsModule/listenedToPodcast',
        podcast.trackingAction
      );

    const onAudioEnds = async ({ podcast }: {podcast: Podcast}) => {
      // Remove the audio component
      currentPlayingTrack.value = null;
      // Display the survey
      surveyUrl.value = podcast.leadfamlyUrl;
      isSurveyDisplayed.value = true;
      // Wait for DOM update
      await nextTick();
      // Scroll into it
      scrollIntoView(refSurvey.value);
    };

    const onAudioFragmentListened = (config: {
      progress: number;
      trackAction: string;
    }) => {
      store.dispatch('podcastsModule/listenedToAudioFragment', config);
    };

    return {
      currentPlayingTrack,
      heroImages,
      isSurveyDisplayed,
      onAudioEnds,
      onListenToPodcast,
      onPodcastFinished,
      podcasts,
      refSurvey,
      surveyUrl,
      token,
      userId,
      CLUB_LOYALTY_URL,
      PointTypesEnum,
      onAudioFragmentListened,
    };
  },
});
